import React, { useRef, useEffect, useState } from 'react'
import { navigate, graphql } from 'gatsby'
import {
  useResolution,
  useLocation,
  getAuth,
  getOrderAfterPaymentData,
  OrderStatus,
  Loading,
  getStoredCity,
  getCartStateByOrderId,
  useShoppingCart,
  sendCheckoutEventToGTM,
  toCurrencyNumber,
} from '@ecommerce/shared'
import { v4 as uuidv4 } from 'uuid'
import AfterPayment from '../../templates/afterPayment'
import { PgPageProps } from '../../types/PgPages'
import Layout from '../../components/Layout'
import { PageContainer } from '../../components/PageContainer'
import withPageTransition from '../../components/withPageTransition'
import secrets from '../../config/secrets'
import { DeviceFingerprintHelmet } from '../../components/Checkout/components/DeviceFingerprintHelmet'
import { customNavBar, stringArrayToElementList, onRedirectBack, PageWrapper } from '../../components/Payment'
import { tagManagerCheckoutActionFields } from '../../utils/gtm'

const { COUNTRY } = secrets

const PaymentCancelled = (props: PgPageProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isOpenCart, setIsOpenCart] = useState(false)
  const [orderNumber, setOrderNumber] = useState<number>(0)

  const {
    title,
    upperText,
    image,
    upperButtonTextUser,
    upperButtonTextGuest,
    bottomText,
  } = props.data.allContentfulPgPage.edges[0].node.template

  const currentCity = getStoredCity()
  const currentSlug = currentCity?.slug

  const refSessionId = useRef(uuidv4())
  const { isDesktop } = useResolution()

  const params = new URLSearchParams(props.location.search)
  const orderId = params.get('req_reference_number') || ''

  const isAuth = getAuth()
  const { isBolivia } = useLocation()
  const isBO = isBolivia()

  const goBack = () => onRedirectBack(currentSlug ? `/${currentSlug}` : '/')

  useEffect(() => {
    if (!orderId || !currentCity) {
      goBack()

      return
    }

    getOrderAfterPaymentData(orderId, COUNTRY)
      .then((data) => {
        if (!data) return goBack()
        sendCheckoutEventToGTM(
          Object.values(data.items).map((product) => ({
            ...product,
            price: toCurrencyNumber(product.price, isBO),
          })),
          tagManagerCheckoutActionFields.cancelled.actionField,
        )

        const { status, number } = data
        setOrderNumber(number)
        const canRetry = status === OrderStatus.PLACED

        if (!canRetry) return goBack()

        setIsLoading(false)
      })
      .catch(goBack)
  }, [])

  const { replaceState, state } = useShoppingCart()

  const retryPayment = async () => {
    if (currentCity) {
      const {
        state: { byHash, globalQuantity, globalTotal, globalRawTotal },
      } = await getCartStateByOrderId(currentCity.commerceLayer.market.number, COUNTRY, orderId)
      replaceState({ ...state, byHash, globalQuantity, globalRawTotal, globalTotal })
      setIsOpenCart(true)
    }
  }

  return (
    <>
      {currentCity && (
        <Layout
          currentCity={currentCity}
          title={title}
          navbar={isDesktop ? undefined : customNavBar(currentSlug)}
          openCart={isOpenCart}
        >
          {currentCity && <DeviceFingerprintHelmet isBO={isBO} city={currentCity} sessionId={refSessionId.current} />}
          <PageWrapper>
            <PageContainer className="PageContainer">
              {isLoading ? (
                <Loading className="Loading" />
              ) : (
                <AfterPayment
                  orderId={orderId}
                  desktopTitle={title}
                  upperText={stringArrayToElementList(upperText, orderNumber)}
                  imgSrc={image.file.url}
                  imgAlt="truck"
                  upperButtonText={isAuth ? upperButtonTextUser : upperButtonTextGuest || upperButtonTextUser}
                  upperButtonAction={retryPayment}
                  lowerButtonText="Ir a Inicio"
                  lowerButtonAction={() => navigate(`/${currentSlug}`)}
                  bottomText={bottomText ? stringArrayToElementList(bottomText, orderNumber) : undefined}
                />
              )}
            </PageContainer>
          </PageWrapper>
        </Layout>
      )}
    </>
  )
}

export default withPageTransition(PaymentCancelled)

export const query = graphql`
  query PaymentCancelQuery($paymentCancelId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $paymentCancelId } }) {
      edges {
        node {
          slug
          title
          contentful_id
          template {
            ... on ContentfulTmAfterPayment {
              id
              image {
                id
                file {
                  url
                }
              }
              upperText
              lowerButtonText
              middleTextUser
              middleTextGuest
              title
              upperButtonTextGuest
              upperButtonTextUser
              bottomText
            }
          }
        }
      }
    }
  }
`
